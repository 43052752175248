
import http from "../../../../common/services/http";
import {
  defineComponent,
  computed,
  ref,
  reactive,
  onMounted,
  watch,
} from "vue";
import moment from "moment";
import router from "../router";
import { format } from "date-fns";

export default defineComponent({
  props: {
    day: {
      type: String,
    },
  },
  setup(props) {
    const form = ref({});

    const varieties = ref([]);

    const search = ref("");
    const variety = reactive({
      name: "",
      code: "",
    });

    const orders = ref([]);

    const agents = ref([]);
    const agent_id = ref(0);

    const customers = ref([]);
    const user_id = ref(0);
    const customer = computed(() => {
      return (
        customers.value?.find((c: any) => c.id == user_id.value) || {
          contact: { currency: "euro" },
        }
      );
    });

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);

    function friday() {
      const date = new Date();
      const last = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000);

      return date.getDay() < 5
        ? new Date(last.setDate(last.getDate() - (last.getDay() - 1) + 4))
        : new Date(date.setDate(date.getDate() - (date.getDay() - 1) + 4));
    }

    const s = props.day ? new Date(props.day) : friday();
    const start = ref(s);
    const end = computed(
      () => new Date(new Date(start.value).getTime() + 6 * 24 * 60 * 60 * 1000)
    );

    function getDates(s: any) {
      const e = new Date(new Date(s).getTime() + 6 * 24 * 60 * 60 * 1000);

      const dates = [];
      let currentDate = moment(s);
      const eDate = moment(e);
      while (currentDate <= eDate) {
        dates.push(moment(currentDate).format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }

      return dates;
    }

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    function fetchAgents() {
      processing.value = true;
      http.get(`/api/users?role=agent&fetch=all`).then((res) => {
        agents.value = res.data;
        processing.value = false;
      });
    }

    function fetchCustomers() {
      processing.value = true;
      http.get(`/api/users?role=customer&fetch=all`).then((res) => {
        customers.value = res.data;
        processing.value = false;
      });
    }

    async function fetchOrders() {
      const res = await http.get(
        `/api/week-variety-orders?start=${fD(
          start.value,
          "yyyy-MM-dd"
        )}&end=${fD(end.value, "yyyy-MM-dd")}`
      );
      orders.value = res.data;

      return res.data;
    }

    function fetchVarieties() {
      processing.value = true;

      fetchOrders().then((os: any) => {
        http.get(`/api/varieties?fetch=all`).then((res) => {
          const a = res.data;
          varieties.value = a;

          const dates = getDates(start.value);

          form.value = os;

          processing.value = false;
        });
      });
    }

    function searchVarieties() {
      fetchOrders().then((os: any) => {
        http.get(`/api/varieties?s=${search.value}&fetch=all`).then((res) => {
          const a = res.data.data;
          varieties.value = a;

          const dates = getDates(start.value);

          form.value = dates.reduce((o, d) => {
            const order: any = os.find((or: any) => or.date == d);

            return {
              ...o,
              [d]: a.reduce(
                (r: any, p: any) => ({
                  ...r,
                  [p.id]: (order?.quantities as any)[p.id] || 0,
                }),
                {}
              ),
            };
          }, {});
        });
      });
    }

    function getPackageSum(p: any) {
        const data = Object.values(form.value);

        return data.reduce((o: any, n: any) => {
          return o + parseFloat(n[p]);
        }, 0);
    }

    function getCurrency() {
      if (customer.value?.contact?.currency == "euro") {
        return "€";
      } else if (customer.value?.contact?.currency == "pound") {
        return "£";
      } else if (customer.value?.contact?.currency == "dollar") {
        return "$";
      } else if (customer.value?.contact?.currency == "shilling") {
        return "KSh";
      }
    }

    function isFilled(p: any) {
      const filled = Object.values(form.value).filter((d: any) => {
        return Number(d[p]) > 0;
      });

      return filled.length > 0;
    }

    onMounted(() => {
      fetchAgents();
      fetchCustomers();
      fetchVarieties();
    });

    watch(
      () => start.value,
      (v: any) => {
        fetchVarieties();
      }
    );

    function updateForm() {
      fetchVarieties();
    }

    return {
      fetchVarieties,
      processing,
      search,
      searchVarieties,
      varieties,
      created,
      variety,
      fD,
      getDates,
      start,
      end,
      form,
      customers,
      customer,
      user_id,
      updateForm,
      getPackageSum,
      getCurrency,
      orders,
      isFilled,
    };
  },
});
